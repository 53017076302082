.news_section{
    flex: 1;
    background: radial-gradient(200% 100% at 100% 0,#0d1015 31.96%,rgba(1,10,48,0) 64.77%),radial-gradient(200% 100% at 0 100%,#0d1015 29.86%,rgba(1,10,48,0) 67.84%),#020b31;
    position: relative;
    padding: 4rem 0rem;
}

.news_box{
    width: 100%;
    height: 100%;
    min-height: 17vh;
    overflow: hidden;
    position: relative;

    box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
}

.shadow_other_box{
    box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
}

.news_box1{
    width: 100%;
    height: 100%;
    min-height: 17vh;
    overflow: hidden;
    position: relative;
}

.news_box_long{
    width: 100%;
    height: 100%;
    min-height: 40vh;
    overflow: hidden;
    position: relative;

    box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
}

.shadowed_box{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #0004117e;
}

.news_box_img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    transition: .5s;
}
.text_news_box_z3_short{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;

    transition: .5s;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: .2rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.text_news_box_z3{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;

    transition: .5s;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: .2rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.text_news_box_z3_long{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;

    transition: .5s;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

.text_news_box_z3_long > div:nth-child(2){
    font-size: 1rem;
    color: rgb(175, 175, 175) !important;
}
.text_news_box_z3_long > div:nth-child(2) > svg{
    color: white !important;
    width: 1.2rem !important;
    padding-bottom: .2rem;
}

.text_news_box_z3_long > div:nth-child(3){
    font-size: 1.2rem;
    padding-top: .3rem;
    cursor: pointer;
}


.text_news_box_z3 > div:nth-child(1),
.text_news_box_z3_short > div:nth-child(1),
.text_news_box_z3_short > div:nth-child(2){
    font-size: .7rem;
    color: rgb(175, 175, 175);
}

.text_news_box_z3 > div:nth-child(1) > svg,
.text_news_box_z3_short > div:nth-child(2) > svg{
    color: white !important;
    width: .9rem !important;
}

.text_news_box_z3 > div:nth-child(2),
.text_news_box_z3_short > div:nth-child(3){
    font-size: .9rem;
    padding-top: .3rem;
    cursor: pointer;
}

.text_news_box_z3 > div:nth-child(2):hover, 
.text_news_box_z3_long > div:nth-child(3):hover,
.title_box_news:hover,
.text_news_box_z3_short > div:nth-child(3):hover{
    color: rgb(255, 243, 181) !important;
}

.paper_box{
    background-color: rgba(255, 255, 255, 0.1);
    color: black;
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: .5rem;
}

.news_access_icon{
    color: #4dc6dc !important;
    width: 1.1rem !important;
    height: auto !important;
    padding-bottom: .15rem;
}

.time_box_news{
    font-size: .8rem;
    color: rgb(175, 175, 175);
}

.title_box_news{
    padding-top: .5rem;
    font-size: .9rem;
    color: white;
    cursor: pointer;
}

.news_box:hover .news_box_img{
    transform: scale(1.1);
}

.news_box1:hover .news_box_img{
    transform: scale(1.1);
}

.news_box_long:hover .news_box_img{
    transform: scale(1.1);
}

.top_news{
    margin-bottom: 2rem;
    background-color: rgb(151, 1, 1);
    width: fit-content;
    color: white !important;
    padding: .5rem 1rem;
    padding-right: 1.5rem !important;
    -webkit-clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
    clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
}

.mr_news{
    margin-bottom: 2rem;
    background-color: #3C96AA;
    width: fit-content;
    color: rgb(255, 255, 255) !important;
    padding: .5rem 1rem;
    padding-right: 1.5rem !important;
    -webkit-clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
    clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
}

.news_latest{
    margin-bottom: 1rem;
    /*background: linear-gradient(180deg, rgba(255, 255, 255, 47.41%) 0%, rgba(255, 255, 255, 0.15) 47.4%, rgba(255, 255, 255, 0) 0), linear-gradient(78.58deg, #4dc6dc 10.59%, #0e1524 91.98%);
    */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 47.4%, rgba(255, 255, 255, 0) 47.41%), linear-gradient(78.58deg, #0e1524 10.59%, #4dc6dc 91.98%);
    width: 100%;
    color: rgb(255, 255, 255) !important;
    padding: .5rem 1rem;
    padding-right: 1.5rem !important;
    position: relative;

    
    box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 8px 16px -2px rgba(0,0,0,0.75);
}

.news_latest:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 0.625rem solid #0E1524;
    border-left: 0.625rem solid transparent;
    border-right: 0.625rem solid transparent;
    position: absolute;
    bottom: -0.625rem;
    left: 1.75rem;
    z-index: 5;
}

.news_det_header{
    position: relative;
    padding: 6.875rem 0;
}

.text_news_det_header{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    transition: .5s;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: .2rem;
    padding-left: .5rem;
    padding-right: .5rem;
}
.passive_link_news{
    color: rgb(175, 175, 175);
}

.passive_link_news:hover{
    text-decoration: underline;
    color: white;
    cursor: pointer;
}

.flex-time_news{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;

    font-size: .8rem;
}

.banner_news_det > .top_news,
.banner_news_det > .mr_news{
    font-size: .7rem;
    margin-bottom: .8rem;
}

.desc_news{
    font-size: 1.5rem;
    padding-top: 2rem;
}

.data_news{
    padding-top: 2rem;
    font-size: .8rem;
}

.banner_img_news_det{
    position: relative;
    width: 35rem;
    height: 20rem;
    margin-top: 2rem;
}