
/* Extra Large devices (desktops) */
@media (min-width: 2025px) {
    .news_det_header{
        padding: 9.875rem 0 !important;
    }
    /*HOME*/
    .MuiContainer-root{
        max-width: 90% !important;
        width: 90%;
    }
    .MuiBox-root{
        font-size: .6vw;
        justify-content: space-evenly;
    }
    .MuiAvatar-root{
        width: 2vw !important;
        height: auto !important;
    }
    .MuiToolbar-regular{
        min-height: fit-content !important;
    }
    .logoMtech {
        width: 1.1vw !important;
        height: 1.35vw !important;
    }
    .h6-header_home{
        font-size: .7vw;
    }
    .play-btn__home, .join-btn__home, .tw_btns_home > .tw-connect-wallet {
        margin-top: 1vw !important;
        font-size: .9vw !important;
    }
    .logo-header {
        width: 80%;
    }
    .title__gameplay{
        font-size: 1.2vw;
        line-height: 2 !important;
    }
    .gameplay__box {
        height: 20vw;
    }
    .sticky_img {
        right: -11vw;
    }
    .section-heroes{
        overflow: hidden;
    }
    .row-heroes__home{
        height: 100%;
        align-items: center;
    }
    .ul__heroes {
        gap: 2vw !important;
        top: 0;
        left: 0;
    }
    .circle-stats__heroes {
        padding: 1vw;
    }
    .listDetails__heroes {
        min-height: 3vw;
    }
    .img-icon-list__heroes {
        width: 1vw;
    }
    .bg-container__heroes {
        width: 15vw;
        left: 2vw;
    }
    .desc-circle__heroes{
        padding-left: 2vw !important;
    }
    .container__heroes {
        width: 50%;
        height: 100%;
        margin: 43% auto;
        perspective: 10000px;
        display: flex;
        align-items: center;
        min-height: 15vw;
    }
    .token_section__token {
        height: 5vw;
    }
    .token_pre__home {
        min-height: 20vw;
    }
    .col_utilities__tokendetails1{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .h1__tokendetails{
        font-size: 1.5vw;
    }
    .val_token_info{
        font-size: 1vw;
    }
    .boxMenu__gameplay{
        font-size: .9vw;
    }
    .title_gameplay_desc, .desc-circle__heroes, .div_roadmap_years, .div_roadmap_years_selected, .season_name__roadmap
    {
        font-size: .8vw;
    }
    .p_gameplay, .subtitle__gameplay, .button__tokendetails, .col_utilities__tokendetails1 > div {
        font-size: .7vw !important;
    }
    .h6__gameplay_subs, .text-desc__gameplay, .desc_token_info, .p__tokendetails,
    .tasks_text__roadmap
    {
        font-size: .6vw;
    }
   
}

/* Large devices (desktops) */
@media (min-width: 1025px) and (max-width: 2024px) {
    .news_det_header{
        padding: 9.875rem 0 !important;
    }
    /*HOME*/
    .desc-circle__heroes{
        padding-left: 1.5rem !important;
    }
    .col_utilities__tokendetails1 div:nth-child(8) {
        left: 23rem !important;
        top: 9rem !important;
    }
}

/* Medium devices (desktops) */
@media (min-width: 769px) and (max-width: 1024px) {
    .img_heronormal{
        width: 80% !important;
    }
    .conteiner_heroesnornmal{
        max-width: 80% !important;
        width: 80% !important;
        min-width: 80% !important;
    }
    .col_utilities__tokendetails1 div:nth-child(4) {
        left: 6rem !important;
    }
    .col_utilities__tokendetails1 div:nth-child(8) {
        left: 10rem !important;
    }
    .tokendetails__section, .grid--after__home{
        overflow: hidden;
    }
    .h6-header_home{
        font-size: .9rem;
    }
    .play-btn__home::before,  .tw_btns_home::before {
        -webkit-animation: allBefore1 2s ease infinite alternate !important;
    }
    @keyframes allBefore1{
        0% {
            left: 0.4375rem;
        }
        
        100% {
            left: 6.5rem;
        }
    }
    .play-btn__home::after,  .tw_btns_home::after {
        -webkit-animation: allAfter1 2s ease infinite alternate;
    }
    @keyframes allAfter1{
        0% {
            left: 0.4375rem;
        }
        
        100% {
            left: 4rem;
        }
    }
    .play-btn__home, .join-btn__home,  .tw_btns_home > .tw-connect-wallet  {
        padding: 0.5rem 3rem !important;
        font-size: .9rem !important;
    }
    .content-header__home{
        width: 65vw;
    }
    .carousel__heroes div{
        transform: rotate3d(0,1,0,calc(72deg* var(--img_no))) translateZ(15rem);
    }
    .ul__heroes{
        gap: 2rem;
    }
    .img-icon-list__heroes {
        width: 1.5rem;
    }
    .bg-container__heroes {
        width: 10rem;
    }
    .desc-circle__heroes {
        left: 4rem !important;
        width: 10rem;
        min-width: 10rem;
    }
    .carousel__heroes div img {
        width: 12rem;
        height: 100%;
        transition: all .8s ease;
    }
    .p_gameplay {
        width: 85%;
    }
    .gameplay__box {
        width: 80vw;
        height: 34rem;
    }    
    .floating_planet {
        left: -6rem;
        bottom: -4rem;
    }
    .sticky_img {
        right: -15rem;
        width: 120%;
        height: 100%;
    }
    .desc_col__gameplay{
        padding: 3rem 2rem 0rem 1rem !important;
    }
    .token_section__token {
        height: 8rem;
        padding: .7%;
        width: 85%;
        left: 8%;
        top: -8%;
    }
    .box__tokendetails {
        width: 85%;
        height: 75%;
    }
    .roadmap_container_s {
        width: 85%;
    }
    .container__heroes {
        width: 100%;
        height: 100px;
        position: relative;
        margin: 30% auto;
    }
    .circle-stats__heroes {
        padding: 1rem;
    }
    .bg-container__heroes {
        left: 2.3rem;
    }
}

/* Small devices (tablets) */
@media (min-width: 601px) and (max-width: 768px) {
    .img_heronormal{
        width: 99% !important;
    }
    .conteiner_heroesnornmal{
        max-width: 90% !important;
        width: 90% !important;
        min-width: 90% !important;
    }
    .col_utilities__tokendetails1 {
        text-align: center;
    }
    .col_utilities__tokendetails1 > div:nth-child(2){
        left: 4rem !important; 
    }
    .col_utilities__tokendetails1 > div:nth-child(3){
        left: 8rem !important; 
    }
    .col_utilities__tokendetails1 > div:nth-child(4){
        left: 1rem !important; 
    }
    .col_utilities__tokendetails1 > div:nth-child(5){
        left: 2rem !important; 
    }
    .col_utilities__tokendetails1 > div:nth-child(7){
        left: 15rem !important; 
        width: fit-content !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(8){
        top: 20rem !important;
        left: 3rem !important;
    }
    .tokendetails__section, .grid--after__home{
        overflow: hidden !important;
    }
    .h6-header_home{
        font-size: .9rem;
    }
    .play-btn__home::before,  .tw_btns_home::before {
        -webkit-animation: allBefore1 2s ease infinite alternate !important;
    }
    @keyframes allBefore1{
        0% {
            left: 0.4375rem;
        }
        
        100% {
            left: 6.5rem;
        }
    }
    .play-btn__home::after,  .tw_btns_home::after {
        -webkit-animation: allAfter1 2s ease infinite alternate;
    }
    @keyframes allAfter1{
        0% {
            left: 0.4375rem;
        }
        
        100% {
            left: 4rem;
        }
    }
    .play-btn__home, .join-btn__home,  .tw_btns_home > .tw-connect-wallet  {
        padding: 0.5rem 1rem !important;
        font-size: .9rem !important;
        min-width: 11rem !important;
        text-align: center !important;
    }
    .content-header__home{
        width: 65vw;
    }
    .carousel__heroes div{
        transform: rotate3d(0,1,0,calc(72deg* var(--img_no))) translateZ(15rem);
    }
    .ul__heroes{
        gap: 2rem;
    }
    .img-icon-list__heroes {
        width: 1.5rem;
    }
    .bg-container__heroes {
        width: 10rem;
    }
    .desc-circle__heroes {
        left: 4rem !important;
        width: 10rem;
        min-width: 10rem;
    }
    .carousel__heroes div img {
        width: 12rem;
        height: 100%;
        transition: all .8s ease;
    }
    .p_gameplay {
        width: 85%;
    }
    .gameplay__box {
        width: 90vw;
        height: 34rem;
    }    
    .floating_planet {
        left: -6rem;
        bottom: -4rem;
    }
    .inner-section__main__home {
        padding: 0rem 1rem;
    }
    .sticky_img {
        display: none;
    }
    .desc_col__gameplay{
        padding: 2rem 2rem 0rem 1rem !important;
    }
    .token_section__token {
        height: 8rem;
        padding: .7%;
        width: 85%;
        left: 8%;
        top: -8%;
    }
    .box__tokendetails {
        width: 85%;
        height: 75%;
    }
    .roadmap_container_s {
        width: 85%;
    }
    .container__heroes {
        width: 100%;
        height: 100px;
        position: relative;
        margin: 85% auto;
    }
    .circle-stats__heroes {
        padding: 1rem;
    }
    .bg-container__heroes {
        left: 2.3rem;
    }
    .text-desc__gameplay{
        width: 100%;
    }
    .val_token_info {
        font-size: 1rem;
    }
    .logo-header {
        width: 35rem;
    }
    .content-header__home{
        width: 65vw;
    }
    .container__heroes {
        width: 100%;
    }
    .carousel__heroes div{
        transform: rotate3d(0,1,0,calc(72deg* var(--img_no))) translateZ(8rem);
    }
    .circle-stats__heroes {
        padding: .5rem;
    }
    .ul__heroes{
        gap: 2rem;
        top: -3rem;
    }
    .img-icon-list__heroes {
        width: 1rem;
    }
    .desc-circle__heroes {
        left: 1.3rem;
        width: 5rem;
        min-width: 5rem;
        min-height: 2rem;
        font-size: .8rem;
    }
    .carousel__heroes div img {
        width: 10rem;
        height: 100%;
        transition: all .8s ease;
    }
    .arrowL__heroes, .arrowR__heroes {
        width: 2.4rem;
        top: 55%;
    }

    .ul__heroes li:nth-child(2), .ul__heroes li:nth-child(4) {
        margin-left: 3rem;
    }
    .ul__heroes li:nth-child(3){
        margin-left: 5rem;
    }
    .p__tokendetails{
        font-size: .9rem;
        padding-top: 0rem;
    }
    .button__tokendetails{
        font-size: .9rem !important;
    }
    
    
}

/* Small devices (phones) */
@media (max-width: 600px) {
    .news_det_header{
        padding: 4.875rem 0 !important;
    }
    .text_news_det_header > .MuiContainer-root > h3{
        display: none;
    }
    .banner_img_news_det{
        width: 100% !important;
        height: 13rem !important;
    }

    .inner-section__home_plus{
        height: 1rem;
    }
    .gameplay__box{
        padding-top: 2rem;
    }
    .gameplay-section {
        padding-bottom: 3rem;
    }
    .row>*{
        flex-shrink: inherit !important;
    }
    .h1-header__home{
        font-size: 2rem !important;
        padding-bottom: .5rem;
    }

    .header-section__home{
        height: 110vh;
    }
    .buttons-header__home {
        padding-top: 0 !important;
        padding-bottom: 5rem;
    }
    .sticky-header__home{
        justify-content: flex-start !important;
        align-content: flex-start;
        display: block;
        height: 90vh;
    }

    .icons-container-menu{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
    }
    .flex-container__footer{
        gap: 0rem;
    }
    .flex-container__footer > .col{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }
    .token_pre__home {
        min-height: 50rem;
    }
    .row__tokendetails{
        flex-direction: column !important;
    }

    .tokendetails__section, .inside-grid__home{
        overflow: hidden;
    }
    .title__gameplay{
        font-size: 1.3rem;
    }
    .title_gameplay_desc{
        font-size: 1.4rem;
    }
    .h6__gameplay_subs{
        font-size: 1.2rem;
    }
    .text-desc__gameplay{
        font-size: .9rem !important;
    }
    .pulse__tokendetails {
        left: 30%;
    }
    .col_utilities__tokendetails1 div {
        font-size: .8rem;
        text-align: center;
    }
    .col_utilities__tokendetails1 > div:nth-child(1){
        left: -2rem !important;
        top: 12rem !important;
        width: 12rem;
    }
    .icons-offcanvas-menu {
        padding-left: 1rem !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(2){
        left: 2rem !important;
        width: 11rem;
        top: 7rem !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(3){
        top: 2rem !important;
        left: 5rem !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(4){
        left: -1rem !important;
        top: 2rem !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(5){
        top: 12rem !important;
        left: 11rem !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(6){
        top: 8rem !important;
        left: 0rem !important; 
    }
    .col_utilities__tokendetails1 > div:nth-child(7){
        top: 8rem !important;
        left: 13rem !important; 
        width: fit-content !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(8){
        top: 12rem !important;
        left: 2rem !important;
    }
    .h6-header_home{
        font-size: .9rem;
    }
    .play-btn__home::before, .tw_btns_home::before  {
        -webkit-animation: allBefore1 2s ease infinite alternate !important;
    }
   
    .play-btn__home::after, .tw_btns_home::after {
        -webkit-animation: allAfter1 2s ease infinite alternate;
    }
    @keyframes allBefore1{
        0% {
            left: 0.4375rem;
        }
        
        100% {
            left: 6.5rem;
        }
    }
    @keyframes allAfter1{
        0% {
            left: 0.4375rem;
        }
        
        100% {
            left: 4rem;
        }
    }
    .play-btn__home, .join-btn__home,  .tw_btns_home > .tw-connect-wallet {
        padding: 0.5rem 0rem !important;
        font-size: .9rem !important;
        width: 100% !important;
        text-align: center !important;
    }
    .content-header__home, .h6-header_home, .buttons-header__home {
        width: 90vw;
    }
    .carousel__heroes div{
        transform: rotate3d(0,1,0,calc(72deg* var(--img_no))) translateZ(10rem);
    }
    .ul__heroes{
        gap: 2rem;
    }
    .img-icon-list__heroes {
        width: 1.5rem;
    }
    .bg-container__heroes {
        width: 10rem;
    }
    .desc-circle__heroes {
        left: 4rem !important;
        width: 10rem;
        min-width: 10rem;
    }
    .carousel__heroes div img {
        width: 12rem;
        height: 100%;
        transition: all .8s ease;
    }
    .gameplay__box_container{
        width: 90vw;
    }
    .menu_col__gameplay{
        width: 100% !important;
        flex-direction: row;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        border-bottom-right-radius: 0px;
    }

    .boxMenu__gameplay {
        font-size: .8rem;
        text-wrap: pretty;
        text-align: center !important;
        line-height: normal;
        padding: 1rem 0rem !important;
        display: flex;
        align-items: center;
    }
    .menu_col__gameplay div:nth-child(1) {
        border-top-right-radius: 0px;
        border-top-left-radius: 25px;
    }
    .menu_col__gameplay div:nth-child(4) {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 25px;
    }
    .row_gameplay__box{
        flex-direction: column-reverse;
        padding: 0 !important;
        margin: 0 !important;
    }
    .p_gameplay {
        width: 95%;
        font-size: .8rem;
        padding-top: 0;
    }
    .gameplay__box {
        width: 100%;
        height: 40rem;
    }    
    .inner-section__main__home {
        flex-direction: column;
    }
    .sponsor-h2 {
        font-size: 14px;
    }
    .col-charac__heroes{
        display: none;
    }
    .floating_planet {
        left: -4rem;
        bottom: -4rem;
    }
    .inner-section__main__home {
        padding: 0rem 1rem;
    }
    .sticky_img {
        top: 20rem !important;
        right: -7rem !important;
        width: 120% !important;
    }
    .desc_col__gameplay{
        padding: 2rem 2rem 0rem 1rem !important;
    }
    .token_section__token {
        height: 6rem;
        padding: .7%;
        width: 85%;
        left: 8%;
        top: 34%;
        
    }
    .box__tokendetails {
        width: 85%;
        height: 85%;
    }
    .roadmap_container_s {
        width: 85%;
    }
    .container__heroes {
        width: 100%;
        height: 250px;
    }
    .carousel__heroes div{
        left: 10px;
        transform: rotate3d(0,1,0,calc(72deg* var(--img_no))) translateZ(8rem);
    }
    .circle-stats__heroes {
        padding: .5rem;
    }
    .ul__heroes{
        display: none;
    }
    .img-icon-list__heroes {
        width: 1rem;
    }
    .desc-circle__heroes {
        left: 1.3rem;
        width: 5rem;
        min-width: 5rem;
        min-height: 2rem;
        font-size: .8rem;
    }
    .carousel__heroes div img {
        width: 10rem;
        height: 100%;
        transition: all .8s ease;
    }

    .img_heronormal1{
        width: 80% !important;
    }

    .h3_guard_desc{
        font-size: 1.2rem !important;
    }

    .p_guard_desc{
        font-size: .9rem !important;
    }

    .img_heronormal{
        width: 100% !important;
    }

    .grid_heroesnormal{
        gap: 5rem !important
    }
    .arrowL__heroes, .arrowR__heroes {
        width: 2.8rem !important;
        top: 27% !important;
    }

    .ul__heroes li:nth-child(2), .ul__heroes li:nth-child(4) {
        margin-left: 0rem;
    }
    .ul__heroes li:nth-child(3){
        margin-left: 0rem;
    }
    .circle-stats__heroes {
        padding: 1rem;
    }
    .bg-container__heroes {
        left: 2.3rem;
    }
    .text-desc__gameplay{
        width: 100%;
    }
        
    .col_token_info{
        height: 100%;
    }
    .desc_token_info{
        font-size: .6rem;
    }
    .val_token_info {
        font-size: .9rem;
    }
    .logo-header {
        width: 20rem;
    }
    .content-header__home{
        width: 65vw;
        padding-top: 2rem;
        padding-bottom: 2rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .container__heroes {
        width: 100%;
    }
    .carousel__heroes div{
        transform: rotate3d(0,1,0,calc(72deg* var(--img_no))) translateZ(8rem);
    }
    .circle-stats__heroes {
        padding: .5rem;
    }
    .ul__heroes{
        gap: 2rem;
        top: -3rem;
    }
    .img-icon-list__heroes {
        width: 1rem;
    }
    .desc-circle__heroes {
        left: 1.3rem;
        width: 5rem;
        min-width: 5rem;
        min-height: 2rem;
        font-size: .8rem;
    }
    .carousel__heroes div img {
        width: 10rem;
        height: 100%;
        transition: all .8s ease;
    }
    .arrowL__heroes, .arrowR__heroes {
        width: 2.5rem;
        top: 60%;
    }
    .row-heroes__home{
        padding-left: 5vw !important;
        padding-top: 5rem !important;
    }

    .ul__heroes li:nth-child(2), .ul__heroes li:nth-child(4) {
        margin-left: 3rem;
    }
    .ul__heroes li:nth-child(3){
        margin-left: 5rem;
    }
    .p__tokendetails{
        font-size: .7rem;
        padding-top: 0rem;
        padding-bottom: 1rem;
    }
    .button__tokendetails{
        font-size: .9rem !important;
    }

    .roadmap-img {
        width: 10rem;
    }
    .icons__roadmap_np {
        font-size: 1.8rem;
    }
    .div_roadmap_years, .div_roadmap_years_selected {
        padding: 0.3rem 2rem;
        font-size: .9rem;
    }
    .col_roadmap_years{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-top: 1rem;
    }

    .col_roadmap_years{
        padding-bottom: 3rem;
    }

    .season_name__roadmap{
        font-size: .9rem !important;
        position: absolute;
        left: -50vw;
        width: 110%;
        text-align: end;
    }
    .tasks_text__roadmap{
        font-size: .7rem !important;
    }
    .row_milestones__roadmap{
        display: flex !important;
        flex-direction: column;
        flex-wrap: nowrap;
        max-width: fit-content;
        width: 50% !important;
        width: fit-content !important;
    }
    .main_row_milestones__roadmap{
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        justify-content: center;
    }
    .p-xs-4 > .col{
        text-align: end;
    }
    .div_container_dashes__roadmap{
        position: relative;
        display: none;
    }
    .p-xs-4{
        padding-top: 1.5rem!important;
    }
    .div__dashes__roadmap{
        position: absolute;
        left: 0;
        top: 0;
        transform:  translateX(-50%) translateY(600%) rotate(-90deg);
        height: fit-content;
        width: 100%;
    }
    .title__footer{
        font-size: .9rem;
    }
    .copyright {
        font-size: .7rem;
    }
    .tasks_container__roadmap {
        width: 90%;
    }
    .fade.modal.show{
        z-index: 111111114 !important;
    }
    .list_settings {
        flex-direction: row !important;
        gap: .4rem !important;
    }

    .questing_page{
        padding-top: 3rem !important;
    }

    .list_settings > .MuiButtonBase-root{
        display: flex;
        flex-direction: column !important;
        flex: 1;
    }

    .list_settings > .MuiButtonBase-root > .MuiListItemText-root{
        display: flex;
        height: 100%;
        width: 100%;
        flex: 1;
        justify-content: flex-end !important;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
    }

    .list_settings > .MuiButtonBase-root > .MuiListItemIcon-root, .list_settings > .MuiButtonBase-root > .MuiListItemText-root{
        justify-content: center;
        text-align: center;
    }

    .list_settings > .MuiButtonBase-root > .MuiListItemText-root > span{
        font-size: .66rem;
        padding-top: .4rem;
    }

    .list_settings > .MuiButtonBase-root > .MuiListItemIcon-root > svg{
        width: 2rem !important;
    }
}
/* Extra small devices (phones) */
@media (max-width: 349px) {
    .text_news_det_header > .MuiContainer-root > h3{
        display: none;
    }
    .banner_img_news_det{
        width: 100% !important;
        height: 9rem !important;
    }
    .logo-header{
        width: 15rem !important;
    }
    .h6-header_home {
        font-size: .8rem !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(2) {
        left: 9rem !important;
        width: 8rem;
        top: 3.8rem !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(1) {
        left: 7rem !important;
        top: 13rem !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(3) {
        top: 9rem !important;
        left: 4rem !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(7) {
        top: 8rem !important;
        left: 9rem !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(8) {
        top: 11rem !important;
        left: -2rem !important;
    }
    .col_utilities__tokendetails1 > div:nth-child(5) {
        top: 13rem !important;
        left: 0rem !important;
    }
    .token_pre__home {
        min-height: 53rem;
    }
    .div_roadmap_years, .div_roadmap_years_selected {
        padding: 0.3rem 1.7rem;
        font-size: .7rem;
    }
    .subtitle__gameplay {
        font-size: .6rem;
    }
    .roadmap-img {
        width: 7rem;
    }
    .icons__roadmap_np {
        font-size: 1rem;
    }
    .season_name__roadmap {
        font-size: .9rem !important;
    }
    .tasks_text__roadmap {
        font-size: .6rem !important;
    }
    .p-xs-4 > .col {
        text-align: end;
        width: 99%;
    }
    .tasks_container__roadmap {
        width: 75%;
    }
    .col_utilities__tokendetails1 div {
        font-size: .6rem;
    }
    .but__tokendetails{
        margin-bottom: 5rem; 
    }
    .button__tokendetails{
        font-size: .7rem !important;
    }
    .p__tokendetails {
        font-size: .6rem;
        padding-top: 0rem;
        padding-bottom: .5rem;
    }
    .val_token_info {
        font-size: 1rem;
    }
    .desc_token_info {
        font-size: .7rem;
    }
    .token_section__token {
        height: 5rem;
    }
    .h1__tokendetails, .title__gameplay {
        font-size: 1.3rem !important;
    }
    .title__footer {
        font-size: .7rem;
    }
    .icons__footer {
        padding-bottom: 1rem;
    }
    .h1__tokendetails, .title__gameplay{
        font-size: 1.1rem !important;
    }
    .p_gameplay {
        font-size: .7rem;
    }
    .subtitle__gameplay {
        margin-bottom: 0;
    }
    .boxMenu__gameplay {
        font-size: .8rem;
        text-wrap: pretty;
        text-align: center;
        line-height: normal;
    }
    .title_gameplay_desc {
        font-size: 1.4rem;
    }
    .text-desc__gameplay {
        font-size: .8rem;
    }
    .h6__gameplay_subs {
        font-size: 1.1rem;
    }
    .play-btn__home, .join-btn__home, .tw_btns_home > .tw-connect-wallet {
        padding: 0.3rem 0rem !important;
        font-size: .9rem !important;
    }
    .play-btn__home::before, .tw_btns_home::before  {
        height: 0.3rem !important;
        top: -0.25rem !important;
    }
    .play-btn__home::after, .tw_btns_home::after {
        height: 0.3rem !important;
        bottom: -0.25rem !important;
    }
    @keyframes allBefore1{
        0% {
            left: 0.4375rem;
        }
        
        100% {
            left: 3rem;
        }
    }
    @keyframes allAfter1{
        0% {
            left: 0.4375rem;
        }
        
        100% {
            left: 2rem;
        }
    }
}
