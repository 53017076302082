.header-section__home{
    /*background: url('../img/bg-header.jpg');*/
    position: absolute;
    width: 100%;
    top: -4rem;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    overflow: hidden;
}

.MuiAppBar-root{
    z-index: 11111112 !important;
    background-color: #0e1524;
}

#background-video{
    position: absolute;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    top: -3rem;
    left: 0;
}

.text-error{
    color: red;
}

.video-blacked{
    position: absolute;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    top: -3rem;
    left: 0;
    background-color: #000000bd;
}

.logo-header{
    width: 100%;
}
.sticky-header__home{
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 80vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
}

.content-header__home{
    z-index: 2;
    position: relative;
    width: 50vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h1-header__home{
    font-size: 3.2rem;
    text-align: center;
    padding-bottom: 1.5rem;
}
.h6-header_home{
    font-size: 1.1rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.712);
}
.buttons-header__home{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-top: 2rem;
}

.other-btns{
    background: linear-gradient(78.58deg, #0e1524 10.59%, #234093 91.98%);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
    border: 1px solid white;
    cursor: pointer;
    color: white;
    padding: .2rem 2rem;
    width: fit-content;
}
.play-btn__home, .join-btn__home{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 47.4%, rgba(255, 255, 255, 0) 47.41%),linear-gradient(78.58deg, #0e1524 10.59%, #4dc6dc 91.98%);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
    border: 2px solid #FFFFFF;
    color: white;
    font-size: 1.2rem;
    padding: .5rem 4rem;
    position: relative;
    cursor: pointer;
    text-align: center;
}

.play-btn__home::before{
    width: 3.6875rem;
    height: 0.5625rem;
    top: -0.25rem;
    left: 0.4375rem;
    -webkit-animation: allBefore 2s ease infinite alternate; 
}

.play-btn__home::after{
    width: 5.25rem;
    height: 0.5625rem;
    bottom: -0.25rem;
    right: 0.4375rem;
    -webkit-animation: allAfter 2s ease infinite alternate; 
}

@keyframes allBefore {
    from{
        left: 0.4375rem;
    }
    to{
        left: 10rem;
    }
}
@keyframes allAfter {
    from{
        right: 0.4375rem;
       
    }
    to{
        right: 8rem;
    }
}


.play-btn__home::before, .play-btn__home::after {
    position: absolute;
    content: "";
    background-color: #fff;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    transform: skewX(-20deg);  
}

/*
.play-btn__home:hover::before{
    left: 10rem;
    transition: 1s;
}

.play-btn__home:hover::after{
    right: 8rem;
    transition: 1s;
}*/


.btn-header__home{
    width: 13rem;
    background-color: #0e1524 !important;
    font-size: .9rem !important;
}


.grid--after__home{
    top: 0rem;
    display: grid;
    grid: [a] 1fr/[b] 1fr;
    position: sticky;
    background: radial-gradient(200% 100% at 100% 0,#0d1015 31.96%,rgba(1,10,48,0) 64.77%),radial-gradient(200% 100% at 0 100%,#0d1015 29.86%,rgba(1,10,48,0) 67.84%),#020b31;
    height: 100%;
    z-index: 1;
}

.inside-grid__home{
    height: inherit;
    position: relative;
}

.top-break__home{
    height: 64px;
    position: absolute;
    top: -32px;
    width: 100%;
}

.top-breaker-t__home{
    background: radial-gradient(100% 100% at 100% 100%,#2a4797 .21%,rgba(105,41,255,0) 100%);
    height: 32px;
    width: 100%;
}

.top-breaker-m__home{
    background: linear-gradient(90deg,#234093 -.04%,#fff3b5 52.07%,#234093); 
    box-shadow: 0 0 8px #0077ff;
    height: 2px;
    width: 100%;
}
.top-breaker-b__home{
    background: radial-gradient(50% 100% at 50% 100%,#2a4797 0,rgba(30,84,183,0) 100%);
    height: 32px;
    transform: matrix(1,0,0,-1,0,0);
    width: 100%;
}

/*HEROES*/
.onTop{
    background: -webkit-gradient(linear,left bottom,left top,color-stop(10%,#0000),color-stop(70%,#000000b3),color-stop(100%,#000));
    height: 230px;
    z-index: 2;
    position: absolute;
    top: 0;
    width: 100%;
}
.onBottom{
    background: -webkit-gradient(linear,left top,left bottom,color-stop(60%,#0000),color-stop(80%,#000000b3),color-stop(100%,#000));
    height: 20%;
    z-index: 2;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.main-heroes{
    background: url('../img/bg-hero-section.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.section-heroes{
    box-sizing: border-box;
    width: 70%;
    margin: auto;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.arrowR__heroes{
    position: absolute;
    right: 4%;
    top: 50%;
    width: 8%;
    z-index: 10;
    cursor: pointer;
    filter: sepia(75%);
}
.arrowL__heroes{
    position: absolute;
    left: 4%;
    top: 50%;
    z-index: 10;
    filter: sepia(75%);
    width: 8%;
    cursor: pointer;
}
.arrowL__heroes:hover, .arrowR__heroes:hover{
    transform: scale(1.1);
    transition: .5s;
}
.container__heroes{
    width: 50%;
    height: 100px;
    position: relative;
    margin: 10% auto;
    perspective: 1000px;
}

.carousel__heroes{
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transform: perspective(10000px) rotateX(0deg);
    transition: .3s;
}

.carousel__heroes div{
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    -webkit-box-reflect: below .2rem linear-gradient(transparent,transparent,transparent, #000500);
    
    transform: rotate3d(0,1,0,calc(60deg* var(--img_no))) translateZ(22rem);
}

.carousel__heroes div:nth-child(1){
    --img_no: 6;
}
.carousel__heroes div:nth-child(2){
    --img_no: 5;
}
.carousel__heroes div:nth-child(3){
    --img_no: 4;
}
.carousel__heroes div:nth-child(4){
    --img_no: 3;
}
.carousel__heroes div:nth-child(5){
    --img_no: 2;
}
.carousel__heroes div:nth-child(6){
    --img_no: 1;
}


.carousel__heroes div img{
    width: 100%;
    height: 100%;
    transition: all .8s ease;
    opacity: 0;
}

.carousel__heroes div img:hover{
    transform: scale(1.1);
}


.col-charac__heroes{
    min-height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.circle-stats__heroes{
    position: relative;
    padding: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.bg-container__heroes{
    z-index: 2;
    position: absolute;
    left: 3.3rem;
    width: 20rem;
    height: 100%;
    border-radius: 0px;
    background: linear-gradient(21deg, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
}

.bg-container__heroes::after{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 0px;
    padding: 2px;
    background: linear-gradient(90deg, #fff3b5, #0000ff00);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    pointer-events: none;
}

.second-bg__stats__heroes{
    position: absolute;
    z-index: 3;
    background: linear-gradient(21deg, rgba(18,89,92,1) 0%, rgba(15,19,41,1) 100%);
    /*background: linear-gradient(0deg, rgba(251,235,79,1) 0%, rgba(240,101,148,1) 100%);;
    background: rgba(2, 6, 46, 0.863);*/
    width: 85%;
    height: 85%;
    border-radius: 50px;
    border: 2px solid rgba(18,89,92,1);
}

.bg-old{
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    z-index: 0;
    top: 0;
    left: 0;
    border: 2px solid #1179A2;
    background: linear-gradient(21deg, rgba(251,235,79,1) 0%, rgba(56,110,163,1) 100%);
}

.img-icon-list__heroes{
    width: 2.5rem;
}

.listDetails__heroes{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    min-height: 5rem;
    min-width: 6rem;
}

.desc-circle__heroes{
    /*background: linear-gradient(348deg, rgba(43, 84, 220, 0.6) -6.97%, rgba(43, 46, 220, 0) 31.75%),rgba(255, 255, 255, 0.1);
    */
    min-height: 5rem;
    min-width: 15rem;
    color: white;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    left: 4rem;
}

.ul__heroes{
    width: 100%;
    left: -2rem;
    top: -3rem;
    gap: 4rem;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    position: absolute;
}

.ul__heroes li:nth-child(2){
    margin-left: 4rem;
}
.ul__heroes li:nth-child(3){
    margin-left: 8rem;
}
.ul__heroes li:nth-child(4){
    margin-left: 4rem;
}

/*GAMEPLAY*/

.gameplay-section{
    padding-bottom: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title__gameplay{
    font-size: 2rem;
    line-height: 2.4375rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
}
.subtitle__gameplay{
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
    color: #fff3b5;
    width: 100%;
    text-transform: uppercase;
    padding-top: 2rem;
}
.p_gameplay{
    width: 50%;
    color: rgba(255, 255, 255, 0.685);
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 0 !important;
}
.gameplay__box{
    width: 60vw;
    height: 30rem;
    padding-top: 4rem;
}
.desc_col__gameplay{
    background: radial-gradient(17.24% 33.64% at 0.52% 109.58%, rgba(43, 90, 220, 0.5) 0%, rgba(43, 46, 220, 0) 100%),radial-gradient(45.68% 91.47% at 54.95% 96.61%, #1515154d 0%, #1d1c1c54 100%);
    height: 100%;
    width: 100%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 3rem 2rem 0rem 4rem !important;
    overflow: hidden;
    position: relative;
}

.hexagon__gameplay{
    position: relative;
    z-index: 20;
    width: 8rem;
    height: 2rem;
    font-size: .9rem;
}
.sticky_img{
    position: absolute;
    right: -13rem;
    width: 80%;
    height: 100%;
    overflow: hidden;
}

.sticky_img__gameplay{
    width: 100%;
}

.desc_col__gameplay::-webkit-scrollbar {
    width: 9px; 
}
  
.desc_col__gameplay::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 6px;
}
  
  .desc_col__gameplay::-webkit-scrollbar-track {
    background-color: #1a1a1a00;
  }
  
  .desc_col__gameplay {
    scrollbar-width: thin;
    scrollbar-color: #333 #1a1a1a88;
  }

.menu_col__gameplay{
    background: rgb(62 61 81 / 19%);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0px !important;
    flex-grow: 1;
}
.boxMenu__gameplay{
    height: 100%;
    width: 100%;
    text-wrap: pretty;
    align-items: center;
    line-height: normal;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    color: #909090;
    white-space: wrap !important;
    line-height: 2.3rem;
    padding: 0rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    z-index: 10;
}
.boxMenu__selected{
    color: #fff;
    background: linear-gradient(285.15deg, rgba(43, 84, 220, 0.6) -6.97%, rgba(43, 46, 220, 0) 31.75%),rgba(255, 255, 255, 0.1)
}
.floating_planet{
    position: absolute;
    left: -8rem;
    bottom: -2rem;
    transform: skew(0deg, 354deg);
}
.menu_col__gameplay div:nth-child(1){
    border-top-right-radius: 25px;
}
.menu_col__gameplay div:nth-child(4){
    border-bottom-right-radius: 25px;
}
.planet__gameplay{
    width: 13rem;
    transform: skew(-10deg, 0);
    animation: levitate 3s infinite;
}
.title_gameplay_desc{
    color: white;
    font-size: 1.9rem;
    font-weight: 600;
    border-bottom: 1px solid rgba(255, 255, 255, 0.377);
}

.text-desc__gameplay{
    color: #ffffffde;
    width: 50%;
}

.h6__gameplay_subs{
    font-size: 1.5rem;
    color: white;
}

@keyframes levitate {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
}
.show-image_animation__gameplay{
    animation: showpic 1s ease forwards;
    -webkit-animation: showpic 1s ease forwards;
}
@keyframes showpic {
    0% {
        right: -25rem;
    }
    100% {
        right: -11rem;
    }
}

.ul__gameplay ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.ul__gameplay li {
    background: linear-gradient(78.58deg, #1c2b4a 10.59%, #23409300 91.98%);
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}

.inner-section__home_plus{
    width: 100%;
    height: 4rem;
    /*background: #e4e2d9;*/
}
.inner-section__home_plus_reverse{
    width: 100%;
    height: 3rem;
    background: #e4e2d9;
}
.inner-section__main__home{
    min-height: fit-content;
    background: #e4e2d9;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    min-height: 10rem;
    align-items: center;
}
.css-ishp_reverse{
    bottom: -1rem;
    width: 100%;
    height: 9rem;
    -webkit-transform: skewY(0deg);
    -moz-transform: skewY(0deg);
    -ms-transform: skewY(0deg);
    transform: skewY(0deg);
    background: linear-gradient(to right, #090D20, #050D26);
    position: relative;
}
.css-ishp_reverse:before {
    content: "";
    position: absolute;
    right: 0;
    top: -2px;
    height: 2rem;
    background: #e4e2d9;
    width: 100%;
    -webkit-transform: skewY(0deg);
    -moz-transform: skewY(0deg);
    -ms-transform: skewY(0deg);
    transform: skewY(0deg);
}
.css-ishp {
    top: -1px;
    width: 100%;
    height: 3rem;
    transform: skewY(1.5deg);
    background: linear-gradient(90deg, rgba(5, 13, 41, 1) 0%, rgba(13, 16, 21, 1) 60%);
    position: relative;
}

.css-ishp:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 3rem;
    background: linear-gradient(90deg, rgba(5, 13, 41, 1) 0%, rgba(13, 16, 21, 1) 60%);
    width: 100%;
    transform: skewY(-2deg);
}

.icon-menu-hover{
    cursor: pointer;
}

.sponsor-h2{
    font-size: 1.7rem;
    padding-right: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;
}
.sponsor-img-iskra{
    width: 200px;
    cursor: pointer;
}
.sponsor-img{
    width: 2.9rem;
    cursor: pointer;
}

.sponsor-img-iskra:hover, .sponsor-img:hover{
    transform: scale(1.2);
    transition: .5s;
}
.sponsor{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 2rem;
}


  .breaker-bottom{
    bottom: -4.8rem;
    height: 64px;
    position: absolute;
    width: 100%;
    z-index: 20;
    z-index: 10000000;
  }
  .top-breaker{
        background: radial-gradient(100% 100% at 100% 100%,#c7b3f400  .21%,rgba(160, 119, 255, 0) 100%);
        height: 32px;
        width: 100%;
    }
    .mid-breaker{
        background: linear-gradient(90deg,#174291 -.04%,#d2e9ff  52.07%,#174291);
        box-shadow: 0 0 8px #0084ff;
        height: 2px;
        width: 100%;
    }
    .bottom-breaker{
        background: radial-gradient(50% 100% at 50% 100%,#1e44c5cf  0,rgba(30,84,183,0) 100%);
        height: 32px;
        transform: matrix(1,0,0,-1,0,0);
        width: 100%;
    }

/* Roadmap.css */
.roadmap_section{
    background: radial-gradient(200% 100% at 100% 0,#0d1015 31.96%,rgba(1,10,48,0) 64.77%),radial-gradient(200% 100% at 0 100%,#0d1015 29.86%,rgba(1,10,48,0) 67.84%),#020b31;
    position: relative;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.roadmap{
    padding-left: 7rem !important;
    padding-right: 7rem!important;
}
.roadmap-img{
    width: 20rem;
}

.roadmap_container_s{
    position: relative;
    z-index: 2;
    width: 70%;
    padding: 5%;
    margin: auto;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.3);
    min-height: 45rem;
    border-radius: 0px;
}

.col_roadmap_years{
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 3rem;
    font-size: 1rem;
    font-weight: bold;
}

.div_roadmap_years, .div_roadmap_years_selected{
    padding: 0.5rem 3rem;
    border: 2px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    border-radius: 0px;
}

.div_roadmap_years_selected{
    color: white;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    position: relative;
    padding: 0.6rem 3rem;
}

.selected_features__roadmap{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: .4rem;
    z-index: -1;
}

.selected_features__roadmap div{
    transform:skewX(-20deg);
    background-color: #fff3b54f;
}
.selected_features__roadmap div:nth-child(1){
    width: .3rem;
}

.selected_features__roadmap div:nth-child(2){
    width: .5rem;
}

.selected_features__roadmap div:nth-child(3){
    width: .9rem;
}

.icons__roadmap_np{
    font-size: 2.8rem;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
}

.div_img__roadmap{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1.6rem;
}

.div__dashes__roadmap{
    border-image: 7 repeating-linear-gradient(300deg, #ffffff49 0, #ffffff49 1em, transparent 0, transparent 2em, #ffffff49 0, #ffffff49 3em, transparent 0, transparent 4em);
    border-bottom: 7px solid transparent;
    padding-top: 1rem;
}

.season_name__roadmap{
    color: green;
    font-size: 1.6rem;
    font-weight: 700;
}

.season_name__roadmap_pending{
    animation: pulsate1 2s ease;
    animation-iteration-count: infinite; 
    color: rgb(255, 243, 181) !important;
}

@keyframes pulsate1 {
    0%   { text-shadow: rgb(255, 243, 181) 1px 0 10px; }
    50%  { text-shadow: rgb(54, 54, 54) 1px 0 10px; }
    100% { text-shadow: rgb(255, 243, 181) 1px 0 10px; }
}

.tasks_container__roadmap{
    margin-top: 0.5rem;
    border-left: 1px dashed #ffffff49;
    padding-left: 1rem;
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0.7rem;
}

.circle-icon__roadmap{
    border-radius: 50%;
    background: #ffffff49;
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.tasks_text__roadmap{
    color: #ffffff86;
    font-weight: 600;
}

/*TOKENOMICS*/
.token_pre__home{
    min-height: 40rem;
    background-color: #e4e2d9;
    position: relative;
}
.tokendetails__section{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box__tokendetails{
    width: 70%;
    height: 60%;
    background: transparent;
    border-radius: 0px;
    position: relative;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.15);
}

.blur__tokendetails{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    backdrop-filter: blur(5px);
    z-index: 5;
    top: 0;
    left: 0;
   
}

.row__tokendetails{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 0px;
    padding: 2rem;
    z-index: 6;
    background: radial-gradient(24.67% 20.81% at 107.44% 5.48%, rgba(43, 90, 220, 0.5) 0%, #e4e2d966 88.2%),#e4e2d966;
    margin: 0 !important;
}

.img1__tokendetails{
    position: absolute;
    right: -3rem;
    width: 8rem !important;
    top: -5rem;
    z-index: 7;
}

.img2__tokendetails{
    position: absolute;
    left: -4rem;
    width: 9rem !important;
    bottom: -6rem;
    z-index: 1;
    transform: scaleX(-1);
}
.token_section__token{
    height: 10rem;
    padding: .7%;
    width: 70%;
    left: 15%;
    top: -8%;
    position: absolute;
    z-index: 10; 
    margin: auto;
    background: radial-gradient(17.24% 35.64% at 70.52% -3.42%, rgba(43, 90, 220, 0.5) 0%, rgba(43, 46, 220, 0) 100%),radial-gradient(45.68% 91.47% at 54.95% 96.61%, #050505 0%, #101010 100%);
}

.inner__token{
    border: 1px solid  #fff3b547;
    width: 100%;
    height: 100%;
}

.h1__tokendetails{
    padding-bottom: 1rem;
    border-bottom: 1px solid black;
    font-size: 2.3rem;
    font-weight: 700;
    color: #000;
}

.p__tokendetails{
    padding-top: 1rem;
    font-size: 1rem;
    color: #000;
}
.col_utilities__tokendetails{
    position: relative;
    display: flex;
}
.col_utilities__tokendetails1 div{
    border-radius: 0px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    color: white;
    cursor: pointer;
}

.col_utilities__tokendetails1 div:hover{
    transform: scale(1.1);
    transition: .5s;
}

.pulse__tokendetails {
    position: absolute !important;
    height: 100px;
    width: 100px !important;
    left: 50%;
    top: 35%;
    opacity: 0;
    border: 1px solid rgba(0, 38, 255, 0.452);
    border-radius: 100px !important;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
  }

.visibleH{
    opacity: 1 !important;
}
  
  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1.0;
    }
    100% {
      transform: scale(1.3, 1.3);
      opacity: 0.0;
    }
  }
  
  .button__tokendetails{
    background: linear-gradient(78.58deg, #0e1524 10.59%, #234093 91.98%) !important;
    border: 1px solid #fff3b5 !important;
    position: relative !important;
    padding: .6rem 2rem !important;
    border-radius: 0px !important;
  }
  /*
  .inner_b__tokendetails{
    position: absolute;
    left: 1%;
    top: 5%;
    width: 98%;
    height: 90%;
    border: 1px solid #fff3b5;
    border-radius: 10px;
  }*/

  .col_token_info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .desc_token_info{
    font-size: 1rem;
    color: white;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
  }

  .val_token_info{
    font-size: 1.8rem;
    font-weight: 600;
    color: rgb(255, 243, 181);
    padding-top: 1.5rem;
  }


@media (max-width: 1649px) and (min-width: 1200px) {
    .carousel__heroes div{
        transform: rotate3d(0,1,0,calc(72deg* var(--img_no))) translateZ(20rem);
    }
    .ul__heroes{
        gap: 2.5rem;
    }
    .img-icon-list__heroes {
        width: 2rem;
    }
    .desc-circle__heroes {
        left: 3.5rem;
    }
}
@media (min-width: 1650px) {
    
}

.btn_login{
    color: white !important;
    border: 1px solid white !important;
    border-radius: 0 !important;
    padding: .3rem 2rem !important;
}

.img_heronormal{
    -webkit-box-reflect: below 0rem linear-gradient(transparent,transparent,transparent, #000500b6);
}

.img_heronormal:hover, .img_heronormal1:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.display_flex_quest_btns {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.display_flex{
    display: flex;
    gap: 1rem;
}

.error_form{
    font-size: .7rem;
    color: red;
    text-align: center;
}

.fallback_home{
    min-height: 100vh;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}