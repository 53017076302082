
/* Extra Large devices (desktops) */
@media (min-width: 2025px) {
    .aboutSection {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: stretch;
    }
    .container__about {
        flex: 1;
    }
    .container_about_header{
        max-width: 60% !important;
        padding-top: 3vw;
    }
    .container_about_header > h1{
        font-size: 1.5vw;
    }
    .alien-frame {
        width: 12vw !important;
        height: 15vw !important;
    }
    .start_col_text__about > h1{
        font-size: 1.5vw;
    }
    .subtitle__about{
        font-size: .8vw;
    }
    .p__about_desc{
        font-size: .8vw !important;
    }
    .fa-xs {
        font-size: 1vw;
    }
    .icon-menu {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p_heading__gameplay{
        font-size: .7vw;
    }
}

/* Large devices (desktops) */
@media (min-width: 1400px) and (max-width: 2024px) {

}

/* Medium devices (desktops) */
@media (min-width: 769px) and (max-width: 1399px) {
    .aboutSection {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: stretch;
    }
    .container__about {
        padding-top: 10% !important;
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
    .container_about_header{
        max-width: 100% !important;
        width: 100% !important;
    }
    .container_about_header > h1{
        font-size: 1.5rem;
    }
    .alien-frame {
        width: 13rem !important;
        height: 15rem !important;
    }
    .start_col_text__about > h1{
        font-size: 1.5rem;
    }
    .subtitle__about{
        font-size: .8rem;
    }
    .p__about_desc{
        font-size: .8rem !important;
    }
    .fa-xs {
        font-size: .8rem;
    }
    .icon-menu {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p_heading__gameplay{
        font-size: .9rem !important;
    }
}

/* Small devices (tablets) */
@media (min-width: 601px) and (max-width: 768px) {
    .aboutSection {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: stretch;
    }
    .container__about {
        padding-top: 10% !important;
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
    .container_about_header{
        max-width: 100% !important;
        width: 100% !important;
    }
    .container_about_header > h1{
        font-size: 1.5rem;
    }
    .alien-frame {
        width: 13rem !important;
        height: 15rem !important;
    }
    .start_col_text__about > h1{
        font-size: 1.5rem;
    }
    .subtitle__about{
        font-size: .8rem;
    }
    .p__about_desc{
        font-size: .8rem !important;
    }
    .fa-xs {
        font-size: .8rem;
    }
    .icon-menu {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p_heading__gameplay{
        font-size: .8rem;
    }

}

/* Small devices (phones) */
@media (max-width: 600px) {
    .aboutSection {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: stretch;
    }
    .container__about {
        padding-top: 20% !important;
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
    .container_about_header{
        max-width: 100% !important;
        width: 100% !important;
    }
    .container_about_header > h1{
        font-size: 1.5rem;
    }
    .alien-frame {
        width: 13rem !important;
        height: 15rem !important;
    }
    .start_col_text__about > h1{
        font-size: 1.5rem;
    }
    .subtitle__about{
        font-size: .8rem;
    }
    .p__about_desc{
        font-size: .8rem !important;
    }
    .fa-xs {
        font-size: .8rem;
    }
    .icon-menu {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p_heading__gameplay{
        font-size: .8rem;
        width: 100% !important;
        text-align: justify !important;
    }
    .start_col__about, .end_col__about{
        justify-content: center !important;
        margin-bottom: 2.5rem !important;
    }
    .start_col_text__about {
        margin-left: 1rem !important;
    }
    .end_col_text__about {
        margin-right: 1rem !important;
    }
    .subtitle__about{
        text-align: center !important;
    }
    .root{
        overflow: hidden;
    }
}

/* Extra small devices (phones) */
@media (max-width: 349px) {
    .container__about {
        padding-top: 20% !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
    .subtitle__about, .fa-xs {
        font-size: .6rem !important;
    }
}
