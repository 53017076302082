
/* Extra Large devices (desktops) */
@media (min-width: 2025px) {
    .tokenomicsSection{
        padding-top: 4vw !important;
    }
    .tokenomicsSection > .container{
        max-width: 70% !important;
        width: 70% !important;
    }
    .tokenomicsSection h1 {
        font-size: 1.5vw;
    }
    .h6__tokenomics{
        font-size: 1vw !important;
    }
    .flex__tokenomics_inner > .ps-3{
        font-size: .8vw;
    }
    .square__tokenomics {
        width: .5vw !important;
        height: .5vw !important;
    }
    .perc__tokenomics {
        font-size: .7vw;
    }
    .table>thead>tr>th {
        font-size: .8vw;
    }
    .table>tbody>tr>td {
        font-size: .7vw;
    }
    .p__tokenomics{
        font-size: .7vw;
    }
    .h6_col__tokenomics{
        font-size: 1vw !important;
    }
    .img_utility__tokenomics {
        width: 8vw !important;
    }
    .row_utility__tokenomics{
        gap: .8vw;
    }
}

/* Large devices (desktops) */
@media (min-width: 1400px) and (max-width: 2024px) {
    .row_utility__tokenomics{
        gap: .5rem;
    }
}

/* Medium devices (desktops) */
@media (min-width: 769px) and (max-width: 1399px) {

    .tokenomicsSection {
        padding: 1rem 1rem !important;
    }
    .h6__tokenomics{
        font-size: 1.4rem !important;
    }
    .row_tokenomics__dist{
        padding-top: 1rem !important;
    }
    .col-desc__tokenomics{
        margin-left: 0rem !important;
    }
    .last_child{
        width: 20% !important;
    }
    .table>thead>tr>th {
        font-size: .9rem;
    }
    .table>tbody>tr>td {
        font-size: .8rem;
    }
    .p__tokenomics{
        font-size: .8rem;
    }
    .row_utility__tokenomics{
        gap: 1rem !important;
    }
    .col_utility__tokenomics{
        width: 31%;
    }
    .h6_col__tokenomics {
        font-size: 1rem !important;
    }
    .img_utility__tokenomics {
        width: 9rem !important;
    }
}

/* Small devices (tablets) */
@media (min-width: 601px) and (max-width: 768px) {
    .subtitle__gameplay {
        font-size: .7rem;
    }
    .h1_title__tokenomics{
        font-size: 1.3rem;
    }
    .tokenomicsSection {
        padding: 1rem 1rem !important;
    }
    .h6__tokenomics{
        font-size: 1.1rem !important;
        margin-bottom: 0 !important;
    }
    .flex__tokenomics_inner > .ps-3{
        font-size: .9rem;
        padding-left: 0.1rem !important;
    }
    .perc__tokenomics {
        font-size: .8rem !important; 
        padding-left: 1rem !important;
    }
    .square__tokenomics{
        width: .8rem !important;
        height: .8rem !important;
    }
    .row_tokenomics__dist{
        padding-top: 1rem !important;
    }
    .col-desc__tokenomics{
        margin-left: 0rem !important;
    }
    .last_child{
        width: 20% !important;
    }
    .table>thead>tr>th {
        font-size: .9rem;
    }
    .table>tbody>tr>td {
        font-size: .8rem;
    }
    .p__tokenomics{
        font-size: .6rem;
    }
    .row_utility__tokenomics{
        gap: 1rem !important;
    }
    .col_utility__tokenomics{
        width: 31% !important;
    }
    .h6_col__tokenomics {
        font-size: 1rem !important;
    }
    .img_utility__tokenomics {
        width: 9rem !important;
    }
}

/* Small devices (phones) */
@media (max-width: 600px) {
    .container_table__tokenomics{
        margin-left: 0 !important;
        padding-right: 0 !important;
        padding-left:  0 !important;
    }
    .row_tokenomics__mobile{
        flex-direction: column;
    }
    .subtitle__gameplay {
        font-size: .7rem;
    }
    .h1_title__tokenomics{
        font-size: 1.3rem;
    }
    .tokenomicsSection {
        padding: 1rem 1rem !important;
    }
    .h6__tokenomics{
        font-size: 1.1rem !important;
        margin-bottom: 0 !important;
        padding-bottom: .3rem !important;
        padding-top: 1rem !important;
    }
    .flex__tokenomics_inner > .ps-3{
        font-size: .9rem;
        padding-left: 0.1rem !important;
    }
    .perc__tokenomics {
        font-size: .8rem !important; 
        padding-left: 1rem !important;
    }
    .square__tokenomics{
        width: .8rem !important;
        height: .8rem !important;
    }
    .row_tokenomics__dist{
        padding-top: 1rem !important;
    }
    .col-desc__tokenomics{
        margin-left: 0rem !important;
    }
    .last_child{
        width: 20% !important;
    }
    .table>thead>tr>th {
        font-size: .43rem;
    }
    .table>tbody>tr>td {
        font-size: .6rem;
    }
    .p__tokenomics{
        font-size: .8rem;
        width: 100% !important;
    }
    .row_utility__tokenomics{
        gap: 1rem !important;
    }
    .col_utility__tokenomics{
        width: 100% !important;
    }
    .h6_col__tokenomics {
        font-size: 1rem !important;
    }
    .img_utility__tokenomics {
        width: 9rem !important;
    }
    .h1_title__tokenomics{
        text-align: start !important;
    }
    .header_container__tokenomics{
        padding-top: .5rem !important;
    }
    .spark__tokenomics_img {
        right: -1rem;
        width: 8rem;
    }
    .spark1__tokenomics_img {
        right: 4rem;
        top: 6rem;
    }
}

/* Extra small devices (phones) */
@media (max-width: 330px) {
    .tokenomicsSection{
        padding-left: 0.2rem !important;
        padding-right: 0.2rem !important;
    }
    .table>thead>tr>th {
        font-size: .3rem;
    }
    .container_table__tokenomics{
        margin-left: 0 !important;
        padding-right: 0 !important;
        padding-left:  0 !important;
    }
    .table>tbody>tr>td {
        font-size: .3rem;
    }
}
