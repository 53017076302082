.aboutSection{
    flex: 1;
    background: radial-gradient(200% 100% at 100% 0,#0d1015 31.96%,rgba(1,10,48,0) 64.77%),radial-gradient(200% 100% at 0 100%,#0d1015 29.86%,rgba(1,10,48,0) 67.84%),#020b31;
    
    /*
    background: url('../img/test-bg.jpg');*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    min-height: 95vh;
}

.container__about{
    padding-top: 10rem;
    padding-left: 20rem;
    padding-right: 20rem;
    position: relative;
    overflow: hidden;
}

.img-des__aboutT{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    transform: scaleX(-1);
}

.img-des__about{
    position: absolute;
    left: 0;
    top: -1rem;
    width: 100%;
    opacity: 0.3;
    transform: scaleX(-1);
}

.img-des2__about{
    position: absolute;
    bottom: 0rem;
    left: 0;
    -webkit-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    transform: scaleY(-1);
    opacity: 0.2;
    width: 100%;
}
.team-section{
    padding-top: 6rem;
}

.img-galactic{
    position: absolute;
    width: 98%;
    height: 98%;
    left: 1%;
    background: url('../img/galactic-bg.jpg');
    background-size: cover;
    filter: hue-rotate(-50deg);
    z-index: 0;
    border-radius: 20px;
    overflow: hidden;
}

.alien-frame {
    position: relative;
    z-index: 2;
    width: 300px;
    height: 350px;
    box-shadow: 0 0 20px rgba(110, 173, 255, 0.8);
    padding: 1%;
    border-radius: 20px;
    overflow: hidden;
}

.alien-frame::before{
    content: '';
    position: absolute;
    width: 170px;
    height: 140%;
    right: 25%;
    top: -25%;
    box-shadow: 5px -4px 20px 2px rgba(255,243,181,1);
    background: linear-gradient(90deg, rgba(255,243,181,1) 0%, rgba(255,243,181,1) 81%);
    animation: rotateAbout 8s linear infinite;
}

.p_heading__gameplay{
    width: 80%;
    color: rgba(255, 255, 255, 0.685);
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 0 !important;
}

@keyframes rotateAbout {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}
/*
.alien-frame::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 25%;
    height: 25%;
    border-top: 3px solid white;
    border-left: 3px solid white;
}

.alien-frame::after {
    content: "";
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 25%;
    height: 25%;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
}*/

.alien-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    z-index: 2;
    position: relative;
    filter: hue-rotate(40deg);
}

.first-alien-image{
    margin-top: 4rem;
}

.start__about{
    display: flex;
    flex-direction: row;
}

.end__about{
    display: flex;
    flex-direction: row-reverse;
}

.start_col__about{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5rem;
}

.end_col__about{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5rem;
}

.start_col_text__about, .end_col_text__about{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 5rem;
    color: white;
}

.start_col_text__about{
    align-content: flex-start;
    align-items: flex-start;
    margin-left: 3rem ;
}

.end_col_text__about{
    align-content: flex-end;
    align-items: flex-end;
    margin-right: 3rem;
}

.subtitle__about{
    color: #fff3b5;
    padding-top: 0rem;
    margin-bottom: 0rem;
    text-transform: uppercase;
}

.p__about_desc{
    color: rgba(255, 255, 255, 0.568);
    font-size: .9rem;
}

.flex-center__about{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: 2rem;
    gap: 0.5rem;
}