.ForgeS {
    width: 100%;
    min-height: 100vh;
    background: radial-gradient(200% 100% at 100% 0, #0d1015c2 31.96%, rgba(1, 10, 48, 0) 64.77%), radial-gradient(200% 100% at 0 100%, #0d1015bd 29.86%, rgba(1, 10, 48, 0) 67.84%), #020b31db;
    color: rgba(255, 255, 255, 1);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.singleSlot {
    background: radial-gradient(200% 100% at 100% 0, #0d1015 31.96%, rgba(1, 10, 48, 0) 64.77%), radial-gradient(200% 100% at 0 100%, #0d1015 29.86%, rgba(1, 10, 48, 0) 67.84%), #020b31;
    width: 80px;
    height: 80px;
    border: 1px solid #4d69a6;
    margin: 0% 20px 0% 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
    background-color: #10192d !important;
    color: white !important;
}

.btn-close {
    color: white;
}

.btn-close {
    filter: var(--bs-btn-close-white-filter);
    opacity: 1;
}

.btn-close {
    --bs-btn-close-color: white !important;
}

.abilityImgg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.singleAbility span {
    font-size: 12px;
}

.formsAb {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.singleAbility.selected>.abilityImgg {
    border: 3px solid #dce61d;
    transition: 0.3s;
}

.singleAbility {
    width: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    cursor: pointer;
}

.selectedDivIm {
    width: 100%;
    height: 100%;
}

.selectedDivIm img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.abilityImgg {
    width: 6rem;
    height: 6rem;
    border: 3px solid #4d69a6;
    margin-bottom: 0.5rem;
}

.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: center !important;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap)* .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.heroes {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: auto;
}

.singleSlot svg {
    position: absolute;
    font-size: 35px;
    z-index: 1;
}

.insideForgeImg {

    width: 100%;
    height: 100%;
    background-image: url('../img/part1.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    filter: brightness(0.5);
}

.insideForgeImg svg {
    font-size: 35px;
}

.slots {
    min-width: 1536px;
    background-color: #10192d;
    height: 130px;
    border-radius: 5px;
    padding-left: 4rem;
    padding-right: 4rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0 0 20px rgb(29 47 87);
}

.topFL {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.topF {
    min-width: 1536px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.topFR {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-end;
    justify-content: center;
    align-items: flex-end;
}
button.btn.btn-primary {
    padding: 0.5rem 3rem;
    box-shadow: 0 0 11px rgb(125 108 155 / 52%);
    border-radius: 5px;
    font-size: 18px;
    background-color: #22365d;
    border: none;
}

button.btn.btn-danger {
    padding: 0.5rem 3rem;
    box-shadow: 0 0 11px rgb(125 108 155 / 52%);
    border-radius: 5px;
    font-size: 18px;
    border: none;
}

.topFL span:first-of-type {
    font-size: 20px;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
    width: 100%;
}

.topFL span:last-of-type {
    text-align: left;
    font-size: 12px;
}
.fade.modal.show {
    z-index: 11111111;
}
.topFR span {
    padding: 0.5rem 1rem;
    box-shadow: 0 0 30px rgb(125 108 155 / 52%);
    border-radius: 5px;
    font-size: 18px;
    background-color: #22365d;
}

.forgeB {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.slotWIthN {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.slotWIthN span {
    color: grey;
    font-size: 12px;
    padding-top: 0.5rem;
}

@media (max-width: 1550px) {
    .topF {
        min-width: AUTO;
        width: 100%;
    }

    .topFL {
        min-width: AUTO;
        width: 500px;
    }

    .topFR {
        min-width: AUTO;
        width: 500px;
    }

    .slots {
        min-width: AUTO;
        width: 100%;
    }

    .heroes {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .slots {
        height: auto;
        padding: 1rem;
        border-radius: 5px;
        padding-left: 4rem;
        padding-right: 4rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .slotWIthN {
        width: 100px;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
}

@media (max-width: 650px) {
    .topF {
        min-width: AUTO;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 4rem;
    }

    .topFL span:first-of-type {
        text-align: center;
    }

    .topFL span:last-of-type {
        text-align: center;
        font-size: 12px;
        margin-bottom: 2rem;
    }
    .topFR {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-end;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 450px) {
    .ForgeS{
        padding: 2rem 1rem;
    }
}