.tokenomicsSection{
    background: radial-gradient(200% 100% at 100% 0,#0d1015 31.96%,rgba(1,10,48,0) 64.77%),radial-gradient(200% 100% at 0 100%,#0d1015 29.86%,rgba(1,10,48,0) 67.84%),#020b31;
    position: relative;
    flex: 1;
    min-height: 95vh;
    padding: 2rem 6rem;
    overflow: hidden;
}

.spark__tokenomics_img{
  position: absolute;
  right: 0rem;
  top: 2rem;
  width: 15rem;
  opacity: 0.4;
  z-index: 1;
}

.spark1__tokenomics_img{
  position: absolute;
  right: 10rem;
  top: 10rem;
  width: 8rem;
  opacity: 0.8;
  z-index: 2;
}

.token_img__tokenomics{
  width: 5rem;
}

.tokenomicsSection h1 {
    padding: 2rem;
    color: white;
    text-transform: uppercase;
}

.donut__tokenomics{
    position: absolute;
    width: 100%;
}

.col-desc__tokenomics{
  margin-left: 6rem;
  
}

.h6__tokenomics{
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color: white;
}

.square__tokenomics{
  width: 1rem;
  height: 1rem;
}
.perc__tokenomics{
  padding-left: 1.8rem;
  font-weight: 700;
  color: white;
}
.flex__tokenomics_inner{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: rgba(255, 255, 255, 0.644);
}

.raphael-group-21-legend{
    display: none !important;
}

.raphael-group-6-background, .raphael-group-5-parentgroup{
    background: transparent !important;
}
.row_tokenomics__dist{
  padding-top: 3rem;
}
.donut-chart {
  position: relative;
  width: 100%;
  margin: auto;
  height: 100%;
    
}
  .donut-chart::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px; /* Adjust the width of the outer white circle as needed */
    height: 50px; /* Adjust the height of the outer white circle as needed */
    background-color: #cce0e2; /* Full white color */
    border-radius: 50%;
    z-index: 999; /* Set a high z-index value to ensure it's above the chart */
  }
  
  .donut-chart::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45px; /* Adjust the width of the black circle as needed */
    height: 45px; /* Adjust the height of the black circle as needed */
    background-color: #000000; /* Black color */
    border-radius: 50%;
    z-index: 1000; /* Set a higher z-index value to ensure it's above the white circle */
  }

  .black-cut-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px; /* Adjust the width of the black cut circle as needed */
    height: 40px; /* Adjust the height of the black cut circle as needed */
    background-color: #cce0e2; /* Black color */
    border-radius: 50%;
    z-index: 1002; /* Set a higher z-index value to ensure it's above the chart */
  }

  .smaller-black-circle {
    width: 32px; /* Adjust the width of the smaller black circle as needed */
    height: 32px; /* Adjust the height of the smaller black circle as needed */
    background-color: #000000; /* Black color */
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  

  .table>thead>tr>th{
    background: transparent !important;
    border: none !important;
    padding: 1rem;
    color: rgba(255, 255, 255, 0.644);
  }

  .table>tbody>tr>td{
    padding: 1rem;
  }

  .table>thead>tr{
    border: none !important;
  }

  .table>:not(caption)>*>* {
    border: 0 !important;
    background-color: #15192C !important;
  }

  .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: #ffffff05 !important;
  }

  .table-bordered>:not(caption)>* {
    border-width: 0 !important;
  }

  .table{
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }

.p__tokenomics{
  color: rgba(255, 255, 255, 0.644);
  width: 80%;
}

.h6_col__tokenomics{
  color: white;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
}

.row_utility__tokenomics{
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
}


.col_utility__tokenomics::before, .col_utility__tokenomics::after {
  position: absolute;
  content: "";
  background-color: rgb(255 243 181 / 46%);
  -webkit-transform: skewY(-20deg);
  -moz-transform: skewY(-20deg);
  -ms-transform: skewY(-20deg);
  transform: skewY(-20deg);  
}


.col_utility__tokenomics::before{
  height: 35%;
  width: 0.3rem;
  top: -0.3rem;
  left: -0.2rem;
}

.col_utility__tokenomics::after{
  height: 10%;
  width: 0.3rem;
  bottom: -0.2rem;
  right: -0.2rem;
}

.col_utility__tokenomics{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;

  background: #15192c8c;
  width: 30%;
  padding: 3rem 1rem !important;
  margin: 0 !important;
  margin-bottom: 2rem !important;
}
.header_container__tokenomics{
  padding-top: 3rem;
}
.row_utility__tokenomics{
  gap: 2rem;
}

.donut_container__tokenomics{
  position: relative;
  padding: 0;
  height: 100%;
  width: 100%;
}


.img_utility__tokenomics{
  width: 12rem;
}