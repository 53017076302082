@font-face {
  font-family: 'brolink'; /*a name to be used later*/
  src: url('./assets/fonts/unispace/Unispace\ Bd.otf'); /*URL to font*/
}

@font-face {
  font-family: 'brolinkNormal'; /*a name to be used later*/
  src: url('./assets/fonts/unispace/Unispace\ Rg.otf'); /*URL to font*/
}


body {
  margin: 0;
  font-family: 'brolink' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'brolink' !important;
}

body::-webkit-scrollbar {
  width: 0.4rem;
  background-color: rgba(0, 0, 0, 0.932);
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00)
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(54, 54, 54, 0.801);
  outline: 0px solid slategrey;
}

.root {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  min-height: 100vh;
}

.connectWALLET {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 47.4%, rgba(255, 255, 255, 0) 47.41%), linear-gradient(78.58deg, #0e1524 10.59%, #4dc6dc 91.98%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  border: 1px solid #FFFFFF;
  color: white;
  font-size: 12px;
  padding: 0.2rem 2rem;
  position: relative;
  cursor: pointer;
}

.connectWALLETM {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 47.4%, rgba(255, 255, 255, 0) 47.41%), linear-gradient(78.58deg, #0e1524 10.59%, #4dc6dc 91.98%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  border: 1px solid #FFFFFF;
  color: white;
  font-size: 12px;
  padding: 0.2rem 2rem;
  position: relative;
  cursor: pointer;
}

/*NAV BAR*/
.MuiPaper-elevation0 {
  background-color: transparent !important;
}

.nav-link {
  color: rgba(255, 255, 255, 1) !important;
}

.nav-link:hover {
  color: #4dc6dc !important;
}

.active {
  color: #4dc6dc !important;
}

div.dropdown-menu.show {
  background-color: #0e1524;
}

a.dropdown-item {
  color: #ffffff;
}

a.dropdown-item:hover {
  background-color: #0e1524;
  color: #4dc6dc;
}

.offcanvas.offcanvas-end {
  background-color: #0e1524;
  color: white;
}

.box-menu-mobile {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo-typo-flex__nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.icons-container-menu {
  display: flex !important;
  gap: 10px;
  align-items: center;
}

.icons-offcanvas-menu {
  position: absolute;
  bottom: 13px;
  right: 13px;
}

.icon-menu {
  border: 1px solid rgba(255, 255, 255, 0.36);
  background-image: linear-gradient(hsl(198 100% 73% / 8%), hsl(263 100% 83% / 8%));
  text-shadow: 0 2px 0 rgb(105 91 91 / 25%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
}


.icon-menu-user {
  border: 1px solid rgba(255, 255, 255, 0.36);
  background-image: linear-gradient(hsl(198 100% 73% / 8%), hsl(263 100% 83% / 8%));
  text-shadow: 0 2px 0 rgb(105 91 91 / 25%);
  border-radius: 0px;
  display: flex;
  align-items: center;
  color: gray;
  padding: .28rem .6rem;
  cursor: pointer;
}
.icon-menu-user-selected{
  border: 1px solid rgb(86, 139, 236) !important;
}

.icon-menu-user-selected > svg:nth-child(2){
  color: rgb(86, 139, 236) !important;
}
.icon-menu-user:hover{
  color: rgb(185, 184, 184);
}

.icon-menu-hover:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: .5s;
}

.divider-whiteTone {
  background-color: #ffffff47 !important;
}

.logoMtech {
  width: 25px !important;
  height: 32px !important;
}

/*FOOTER*/
.footer-section {
  color: #fff;
  background-color: #0e1524;
  min-height: 12rem;
  box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.title__footer {
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffffb0;
}

.flex-container__footer {
  display: flex;
  gap: 4rem;
}

.icons__footer {
  display: flex;
  gap: 1rem;
}

.copyright {
  color: #ffffffc2;
  font-size: .8rem;
}

.offcanvas-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}

.icons-offcanvas-menu {
  position: relative;
  bottom: 13px;
  right: 13px;
  padding-left: 3rem;
}
div#radix-\:rg\: {
  z-index: 1111111111111111!important;
}
span.nameLg {
  padding-left: 1rem;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 0px #5cc9dd;
  color: #c8e4ea;
  letter-spacing: 1px;
}
span.nameLg2 {
  padding-left: 1rem;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 0px #5cc9dd;
  color: #c8e4ea;
  letter-spacing: 1px;
}
.offcanvas-title.h5 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  span.nameLg2{
    display: none;
  }
}

@media (max-width: 500px) {
  .connectWALLET {
    display: none;
  }
  #dess1 {
    display: none!important;
}

.css-14rd2dr {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between !important;
  align-items: center !important;

}
}

@media (max-width: 300px) {
  .icon-menu {
    font-size: .7rem;
  }

  .logoMtech {
    width: 21px !important;
    height: 25px !important;
  }

  .logoIskra {
    width: 22px !important;
    height: 27px !important;
  }
}

.css-l00udi{
  padding: 9px 10px !important;
  background: hsl(230deg 11.63% 8.43%) !important;
  border: 1px solid hsl(230deg 11.63% 17%) !important;
  color: white !important;
}
.tw-connected-wallet{
  padding: 0.1em 1rem !important;
}

.tw-connected-wallet > div> img{
  width: 24px !important;
  height: auto !important;
}

.tw-connected-wallet > div:nth-child(2){
  gap: .1rem !important;
}

.text-red{
  color: red;
  font-size: .9rem;
}

.css-d68f7t-MuiPaper-root-MuiAppBar-root{
  background-image: none !important;
}

input{
  text-transform: none !important;
}

button, input, .MuiFormLabel-root{
  font-family: brolinkNormal !important;
  font-size: .8rem !important;
}

.MuiAlert-message {
  font-family: brolinkNormal !important;
  font-size: .76rem !important;
}

.MuiToggleButtonGroup-grouped{
  font-size: .76rem !important;
  padding: .3rem 1rem !important;
}

.list_menu_user > li > div > div > span{
  font-size: .7rem !important;
  font-family: 'brolink' !important;
}

.list_menu_user > li > div > div > svg{
  width: 1.2rem !important;
  height: auto;
  color: rgb(97, 148, 241) !important;
}

.create_btn{
  color: rgb(165, 165, 165) !important;
  background-image: linear-gradient(hsl(198 100% 73% / 8%), hsl(263 100% 83% / 8%));
  font-size: .8rem !important;
  font-family: 'brolink' !important;
  border: 1px solid rgba(255, 255, 255, 0.36) !important;
  border-radius: 0 !important;
  padding: .3rem .6rem !important;
}

.create_btn:hover{
  color: rgb(209, 209, 209) !important;
}

.wallet_ellipsis{
  display: inline-block;
  vertical-align: bottom;
  white-space: nowrap;
  width: 100%;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create_btn_img{
  width: 1.6rem !important;
  height: 1.6rem;
  overflow: hidden;
  border: 1px solid rgba(128, 128, 128, 0.267);
  border-radius: 50%;
  padding: 0rem;
  margin-right: .4rem;
  background-color: rgba(128, 128, 128, 0.267);
}

.modal-header{
  border-bottom: none !important;
}
.modal-footer{
  border-top: none !important;
}

.body_setacc{
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
}

.link_text{
  color: #4dc6dc;
  cursor: pointer;
}

.link_text:hover{
  text-decoration: underline;
}

.profile_btn_img{
  width: 6rem !important;
  height: 6rem !important;
  overflow: hidden;
  border: 1px solid rgba(128, 128, 128, 0.267);
  border-radius: 50%;
  padding: 0rem;
  margin-right: .4rem;
  background-color: rgba(128, 128, 128, 0.267);
  cursor: pointer;
  margin-bottom: .5rem;
}

.profile_btn_img > img, .create_btn_img > img{
  height: 100% !important;
}

.profile_label_acc{
  color: #b2b2b4;
  font-size: .67rem;
  padding-bottom: .3rem;
}

.profile_btn_img:hover{
  background-color: rgba(160, 160, 160, 0.267);
}

.create_btn_ellipsis{
  white-space: nowrap !important;
  display: inline-block !important;
}



.tw_btns > .tw-connect-wallet{
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 47.4%, rgba(255, 255, 255, 0) 47.41%),linear-gradient(78.58deg, #0e1524 10.59%, #4dc6dc 91.98%) !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35) !important;
  border: 2px solid #FFFFFF !important;
  color: white !important; 
  font-size: .8rem !important;
  padding: 0rem 2rem !important;
  position: relative !important;
  cursor: pointer !important;
  text-align: center !important;
  border-radius: 0px !important;
  line-height: 1.5 !important;
  font-family: 'brolinkNormal' !important;
  text-transform: uppercase !important;
  height: 35px !important;
  min-width: fit-content !important;
}

.tw_btns > .tw-connected-wallet{
  padding: .3rem 1rem !important;
  border-radius: 0px !important;
  line-height: 1.5 !important;
  min-width: fit-content !important;
  height: auto !important;
  background-image: linear-gradient(hsl(198 100% 73% / 8%), hsl(263 100% 83% / 8%)) !important;
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.36) !important;
}
.tw_btns > .tw-connected-wallet > div:nth-child(1){
  width: 25px !important;
  height: 25px !important;
}
.tw_btns > .tw-connected-wallet > div:nth-child(2) > span{
  font-size: .6rem !important;
}

.tw_btns_home > .tw-connect-wallet{
  font-size: 1.2rem !important;
  padding: .5rem 4rem !important;
  position: relative !important;
  line-height: 1.5 !important;
  height: fit-content !important;
} 
.tw_btns_home{
  width: 100%;
  height: 100%;
  position: relative;
}


.tw_btns_home::before, .tw_btns_home::after {
  position: absolute;
  content: "";
  background-color: #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  transform: skewX(-20deg);  
  z-index: 99;
}
.tw_btns_home::before{
  width: 3.6875rem;
  height: 0.5625rem;
  top: -0.25rem;
  left: 0.4375rem; 
  animation: allBefore 2s ease infinite alternate; 
  -webkit-animation: allBefore 2s ease infinite alternate; 
}

.tw_btns_home::after{
  width: 5.25rem;
  height: 0.5625rem;
  bottom: -0.25rem;
  right: 0.4375rem;
  animation: allAfter 2s ease infinite alternate; 
  -webkit-animation: allAfter 2s ease infinite alternate; 
}

@keyframes allBefore {
  from{
      left: 0.4375rem;
  }
  to{
      left: 10rem;
  }
}
@keyframes allAfter {
  from{
      right: 0.4375rem;
     
  }
  to{
      right: 8rem;
  }
}


.guardians_desc{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #15192c8c;
  width: 30%;
  padding: 3rem 2rem !important;
  margin: 0 !important;
  margin-bottom: 2rem !important;
}

.guardians_desc::before, .guardians_desc::after {
  position: absolute;
  content: "";
  background-color: rgb(255 243 181 / 46%);
  transform: skewY(-20deg);
}

.guardians_desc::before {
  height: 35%;
  width: 0.3rem;
  top: -0.3rem;
  left: -0.2rem;
}


.guardians_desc::after {
  height: 10%;
  width: 0.3rem;
  bottom: -0.2rem;
  right: -0.2rem;
}

.h3_guard_desc{
  text-align: left;
  width: 100%;
}

.text-gray{
  color: gray;
}

.widget-preview__frame {
  border: none;
  inset: 0;
  margin: 0 auto;
  vertical-align: middle;
  --glm-widget-radius: 10px;
  --glm-widget-header-media-radius: 8px;
  --glm-widget-header-media-margin: 8px;
  --glm-widget-action-radius: 8px;
  --glm-widget-actions-padding: 8px;
  --glm-widget-actions-gap: 6px;
  --glm-widget-padding: 8px;
  background: transparent !important;
  border-radius: var(--glm-widget-radius);
}


.fixedAppBar {
  background-color: transparent !important;
  box-shadow: none !important;
  background-image: none !important;
}