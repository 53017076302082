.play_section{
    flex: 1;
    position: relative;
    background: url('../img/bg_play.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    min-height: 90vh;
}

.play_darker{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-color: #0d10157c;
    z-index: 1;
}

#unity-container { position: absolute; z-index: 2; }

#unity-canvas{
    background: #231F20;
    -webkit-box-shadow: 2px 11px 16px 5px rgba(0,0,0,0.71);
    -moz-box-shadow: 2px 11px 16px 5px rgba(0,0,0,0.71);
    box-shadow: 2px 11px 16px 5px rgba(0,0,0,0.71);
    border: 1px solid rgba(220, 222, 255, 0.671);
}

#unity-loading-bar { 
    position: absolute; 
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%);
    display: none;
}

#unity-logo { width: 154px; height: 130px; background: url('../img/unity/unity-logo-light.png') no-repeat center }
#unity-progress-bar-empty { width: 141px; height: 18px; margin-top: 10px; margin-left: 6.5px; background: url('../img/unity/progress-bar-empty-dark.png') no-repeat center }

#unity-footer { 
    position: relative;
   
    padding: .3rem;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
}

#unity-webgl-logo { 
    width: 204px; 
    height: 38px; 
    background: url('../img/unity/webgl-logo.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#unity-build-title { margin-right: 10px; line-height: 38px; font-size: 18px }
#unity-fullscreen-button { 
    width: 38px; 
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c8e4ea61;
    border-radius: 10px;
    cursor: pointer;
}
#unity-fullscreen-button:hover {
    background: #c8e4ea83;
} 
#unity-progress-bar-full { 
    width: 0%; height: 18px; margin-top: 10px; 
    background: url('../img/unity/progress-bar-full-dark.png') no-repeat center 
}
#unity-warning { position: absolute; left: 50%; top: 5%; transform: translate(-50%); background: white; padding: 10px; display: none; color: red }
.unity-mobile #unity-footer { display: none }
.unity-mobile #unity-canvas { width: 100%; height: 100% }
#unity-container.unity-mobile { width: 100%; height: 100%; }

#unity-container.unity-desktop {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 1390px) {
    .unity-desktop #unity-canvas{
        width: 68vw !important;
        height: 55vh !important;
    }
}

@media (max-width:850px) {
    .unity-desktop #unity-canvas{
        width: 95vw !important;
        height: 55vh !important;
    }
}

@media (max-width:730px) {
    .unity-desktop #unity-canvas{
        width: 95vw !important;
        height: 55vh !important;
    }
}

@media (max-width:670px) {
    .unity-desktop #unity-canvas{
        width: 95vw !important;
        height: 45vh !important;
    }
}

@media (max-width:450px) {
    .unity-desktop #unity-canvas{
        width: 95vw !important;
        height: 25vh !important;
    }
}