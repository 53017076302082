.questing_page{
    flex: 1;
    background: radial-gradient(200% 100% at 100% 0, #0d1015 31.96%, rgba(1, 10, 48, 0) 64.77%), radial-gradient(200% 100% at 0 100%, #0d1015 29.86%, rgba(1, 10, 48, 0) 67.84%), #020b31;
    

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.sign_ques_cont{
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sign_in_par_quest{
    background-color: #15192c8c;
    min-height: 1rem;
    position: relative;
    padding: 1rem 1rem;
}

.sign_in_par_quest::before{
    height: 35%;
    width: 0.3rem;
    top: -0.3rem;
    left: -0.2rem;
}

.sign_in_par_quest::before, .sign_in_par_quest::after{
    position: absolute;
    content: "";
    background-color: rgb(255 243 181 / 46%);
    transform: skewY(-20deg);
}

.sign_in_par_quest::after {
    height: 10%;
    width: 0.3rem;
    bottom: -0.2rem;
    right: -0.2rem;
}

.h6_questing{
    color: white;
    text-align: center;
}

.input_quest{
    width: 100%;
}

.form_questing{

    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    padding: 2rem 2rem;
}

.btn_quest{
    margin-top: 1rem !important;
}

.p_questing{
    color: rgba(255, 255, 255, 0.514);
    text-align: center;
    font-size: .9rem;
}

.p_questing > span{
    color: white;
    cursor: pointer;
    transition: .3s;
}

.p_questing > span:hover{
    color: #234093;
}

.css-1exqwzz-MuiSnackbarContent-message{
    padding: 0 !important;
}

.MuiSnackbarContent-root{
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)) !important;   
    color: rgb(204, 232, 205);
    background-color: rgb(12, 19, 13) !important;
}

.icon_input{
    font-size: .9rem !important;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.719);
    transition: .2s;
}

.icon_input:hover{
    color: white;
}

.btn_group_quest{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 1rem;
}

.btn_group_quest > button{
    width: 100% !important;
}

.btn_group_quest > button:nth-child(2){
    background-color: gray !important;
}

.p_desc{
    color: gray;
    font-size: .9rem;
    padding-bottom: .1rem !important;
    margin-top: .3rem;
    margin-bottom: 0 !important;
}

.css-1g4r0x9-MuiButtonBase-root-MuiToggleButton-root{
    text-transform: none !important;
}

.quest_link_row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.quest_cont{
    margin: 1rem 0rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1rem;
    min-height: 30vh;
    background: #00000054;
    padding: 1rem 1rem;
    border-radius: 10px;
}

.display_center{
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;
}

.quest_btn{
    padding: .3rem 1rem;
    background-color: rgb(1, 173, 1);
    border-radius: 10px;
    cursor: pointer;
    transition: .3s;
    font-size: .8rem !important;
    text-align: center;
}

.quest_btn:hover{
    background-color: rgb(1, 197, 1); 
}

.quest_name{
    font-size: .8rem;
    font-weight: 600;
}

.no_t_quests{
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    color: rgb(201, 201, 201);
}


.list_settings > div > div {
    margin: 0 !important;
}
.list_settings > div > div > span{
    font-family: brolinkNormal !important;
    font-size: 1.3rem;
}

.list_settings > div > div > svg {
    width: 3rem;
    background-color: rgb(97, 148, 241);
    border-radius: 50%;
    padding: .4rem;
    height: auto;
}

.text_settings_active{
    color: rgb(125, 161, 228) !important;
}


.list_settings > div > .MuiListItemIcon-root{
    min-width: 70px;
    color: rgb(211, 221, 238) !important;
}

.list_settings > div:hover, .list_settings > div:focus{
    background-color: none !important;
    background: none !important;
}

.list_settings{
    gap: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.p_settings{
    color: gray;
    font-size: .8rem ;
}

.provider_settings{
    width: 3rem !important;
    height: 3rem !important;
    border: 1px solid rgba(128, 128, 128, 0.267);
    border-radius: 50%;
    padding: .1rem;
    margin-right: .4rem;
}